import React, { useState, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Box,
  Checkbox,
  Center,
  Image,
  Divider,
  Spinner,
  Alert,
  useToast,
  IconButton,
  CloseIcon,
  Pressable,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import LeadsWatchInput from "../../components/LeadsWatchInput";
import { useForm } from "react-hook-form";

import { aw, ah } from "../../utils";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import { register } from "../../store/actions/auth";
import ToastAlert from "../../components/ToastAlert";
import LeadsWatchEyeIcon from "../../components/svg/LeadsWatchEyeIcon";
import LeadsWatchEyeOffIcon from "../../components/svg/LeadsWatchEyeOffIcon";

const Register = () => {
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = localStorage.getItem("remember");
  const credentials = JSON.parse(data);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
  });

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    try {
      await dispatch(
        register(data.firstName, data.lastName, data.email, data.password)
      );
      navigate("/");
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setLoading(false);
  };

  return (
    <HStack width={aw(1366)} flex={1} height={ah(768)}>
      <HStack width="50%" justifyContent={"center"}>
        <VStack>
          <Text
            color="#0000FF"
            fontFamily={"RobotoMedium"}
            mt={ah(64)}
            fontSize={aw(22)}
          >
            Shadow Referral
          </Text>
          <Text
            fontFamily={"RobotoMedium"}
            mt={ah(18)}
            fontSize={aw(30)}
            color="#212121"
          >
            Create an account
          </Text>

          <Text
            fontFamily={"RobotoMedium"}
            fontSize={aw(15)}
            color={"red.600"}
            mt={ah(5)}
            ml={ah(4)}
          >
            {error.login && error.login}
          </Text>
          <LeadsWatchInput
            style={{ marginTop: ah(20), marginBottom: ah(17), width: aw(320) }}
            height={ah(50)}
            labelstyle={{ marginBottom: aw(6) }}
            control={control}
            name="firstName"
            placeholder="Enter firstName"
            borderRadius={"10px"}
            borderWidth={"2px"}
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                First Name
              </Text>
            }
            variant="rounded"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.firstName ? true : false}
            error={errors.firstName ? errors.firstName.message : ""}
          />
          <LeadsWatchInput
            style={{ marginTop: ah(20), marginBottom: ah(17), width: aw(320) }}
            height={ah(50)}
            labelstyle={{ marginBottom: aw(6) }}
            control={control}
            name="lastName"
            placeholder="Enter lastName"
            borderRadius={"10px"}
            borderWidth={"2px"}
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                lastName
              </Text>
            }
            variant="rounded"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.lastName ? true : false}
            error={errors.lastName ? errors.lastName.message : ""}
          />
          <LeadsWatchInput
            style={{ marginTop: ah(20), marginBottom: ah(17), width: aw(320) }}
            height={ah(50)}
            labelstyle={{ marginBottom: aw(6) }}
            control={control}
            name="email"
            placeholder="Enter Email"
            borderRadius={"10px"}
            borderWidth={"2px"}
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Email
              </Text>
            }
            // type="email"
            // leftIcon={
            //   <LeadsWatchEmailIcon color={errors.email ? "red" : "#919191"} />
            // }
            variant="rounded"
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors.email ? true : false}
            error={errors.email ? errors.email.message : ""}
          />

          <LeadsWatchInput
            borderRadius={"10px"}
            borderWidth={"2px"}
            labelstyle={{ marginBottom: aw(6) }}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            control={control}
            name="password"
            placeholder="Enter password"
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Password
              </Text>
            }
            rules={{
              required: {
                value: true,
              },
              // pattern: {
              //   value:
              //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              // },
            }}
            invalid={errors.password ? true : false}
            error={errors.password ? errors.password.message : ""}
            type={show ? "text" : "password"}
            rightIcon={
              !show ? (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
          />

          <HStack justifyContent="space-between" mt={ah(50)}>
            <LeadsWatchButton
              // onPress={() => {
              //   localStorage.setItem("auth", "20");
              //   window.location.reload();
              // }}
              onPress={handleSubmit(onSubmit)}
              color="#FAFBFD"
              isLoading={loading}
              style={{
                width: aw(320),
                height: ah(50),
                borderRadius: "10px",
                marginTop: aw(19),
              }}
              label="Create An Account"
              bg="#0000FF"
              borderColor="#FAFBFD"
            />
          </HStack>

          <HStack justifyContent="center" mt={ah(29)} space={aw(2)}>
            <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#494440">
              Already have an account?
            </Text>
            <Text
              fontSize={aw(14)}
              fontFamily="RobotoMedium"
              color="#0000FF"
              borderBottomWidth="1px"
              borderBottomColor="#0000FF"
              onPress={() => navigate("/")}
            >
              Login
            </Text>
          </HStack>
        </VStack>
      </HStack>
      <HStack width="50%" justifyContent="center" alignItems={"center"}>
        <Image
          width={aw(652)}
          height={ah(708)}
          justifyContent={"center"}
          alignItems="center"
          borderRadius="20px"
          source="/assets/registerBg.png"
          position="absolute"
          top={ah(30)}
          right={aw(30)}
          bottom={ah(30)}
          alt="bgImage"
        />
      </HStack>
    </HStack>
  );
};

export default Register;
