import { VStack, Text, NativeBaseProvider, HStack } from "native-base";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";

import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";
import Details from "./screens/dashboard/Details";

const App = () => {
  const token = localStorage.getItem("auth");
  return token ? (
    <Router>
      <Routes>
        <Route path="/" element={<Details />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  );
};

export default App;
