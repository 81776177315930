import * as events from "../Events";
const token = localStorage.getItem("auth");

//get all details
export const allDetails = (page, count) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          skip:
            parseInt(page) === 1 ? 0 : (parseInt(page) - 1) * parseInt(count),
          limit: parseInt(count),
        }),
      });
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const allDetails = resData;
        dispatch({ type: events.GET_ALL_DETAILS, data: allDetails });

        return allDetails ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};

//Add Details

export const addDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const newData = resData.data;
        dispatch({ type: events.ADD_NEW_DETAILS, data: newData });

        return newData ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};

//Update Details

export const editDetails = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const editedData = resData.data;
        dispatch({ type: events.EDIT_DETAILS, data: editedData });

        return editedData ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};

//single Details

export const singleDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const singleDetails = resData.data;
        dispatch({ type: events.GET_SINGLE_DETAILS, data: singleDetails });

        return singleDetails ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
