import React, { useState, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Box,
  Checkbox,
  Center,
  Image,
  Divider,
  Spinner,
  Alert,
  useToast,
  IconButton,
  CloseIcon,
  Pressable,
  FlatList,
  ScrollView,
  Modal,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

import ToastAlert from "../../components/ToastAlert";
import { aw, ah } from "../../utils";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import { useForm } from "react-hook-form";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchEyeIcon from "../../components/svg/LeadsWatchEyeIcon";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchTopBar from "../../components/LeadsWatchTopBar";
import {
  allDetails,
  addDetails,
  editDetails,
  singleDetails,
} from "../../store/actions/details";

const Details = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const details = useSelector((state) => state.details.allDetails);
  // const singleData = useSelector((state) => state.details.singleDetails);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectCount, setSeletCount] = useState("10");
  const [edit, setEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singleData, setSingleData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      select: "10",
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const handleAdd = async (data) => {
    console.log(data);
    setLoading(true);
    if (edit) {
      try {
        await dispatch(
          editDetails(edit._id, {
            months: parseInt(data.expiry_date),
            code: data.referral_code,
            percent: parseInt(data.percent),
            expires: parseInt(data.expires),
          })
        );
        fetchDeatils();
        setIsOpen(false);

        reset();
        setEdit(null);
      } catch (err) {
        toast.show({
          placement: "top",
          render: () => {
            return (
              <ToastAlert
                desc={err.message}
                // handleClose={() => toast.close()}
                status="error"
                title="error"
              />
            );
          },
        });
      }
    } else {
      try {
        await dispatch(
          addDetails({
            months: parseInt(data.expiry_date),
            code: data.referral_code,
            percent: parseInt(data.percent),
            expires: parseInt(data.expires),
          })
        );
        fetchDeatils();
        setIsOpen(false);
        reset();
      } catch (err) {
        toast.show({
          placement: "top",
          render: () => {
            return (
              <ToastAlert
                desc={err.message}
                // handleClose={() => toast.close()}
                status="error"
                title="error"
              />
            );
          },
        });
      }
    }
    setLoading(false);
  };
  const handleChangeSelect = (value) => {
    setSeletCount(value);
    setCurrentPage(1);
  };
  const fetchDeatils = async () => {
    setIsLoading(true);
    try {
      await dispatch(allDetails(currentPage, selectCount));
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDeatils();
  }, [currentPage, selectCount]);

  //for edit
  const handleEdit = (item) => {
    setEdit(item);
    setIsOpen(true);
  };
  useEffect(() => {
    if (edit) {
      let date1 = moment(edit.created);
      let date2 = moment(edit.expires);
      let difference = date2.diff(date1, "days");
      reset({
        expiry_date: edit.months,
        referral_code: edit.code,
        percent: edit.percent,
        expires: difference,
      });
    } else {
      reset({ expiry_date: "", referral_code: "", percent: "", expires: "" });
    }
  }, [edit]);
  const handleView = async (item) => {
    setViewModalOpen(true);
    setSingleData(item);
    // try {
    //   await dispatch(singleDetails(id));
    // } catch (err) {
    //   toast.show({
    //     placement: "top",
    //     render: () => {
    //       return (
    //         <ToastAlert
    //           desc={err.message}
    //           // handleClose={() => toast.close()}
    //           status="error"
    //           title="error"
    //         />
    //       );
    //     },
    //   });
    // }
  };
  return (
    <VStack width={aw(1248)} height={ah(768)}>
      <LeadsWatchTopBar />
      <VStack ml={aw(55)} space={ah(18)} width={aw(1248)}>
        <VStack>
          <HStack space={aw(8)} alignItems={"baseline"}>
            <Text fontSize={aw(26)} color={"#121233"} fontFamily="RobotoMedium">
              Referrals
            </Text>
            <Text fontSize={aw(18)} color={"#919191"} fontFamily="RobotoMedium">
              {details && details.count}
            </Text>
          </HStack>
          <Text
            lineHeight={ah(34)}
            fontSize={aw(14)}
            fontFamily={"RobotoRegular"}
            color={"#919191"}
            fontWeight={400}
          >
            Your all referrals list will be shown here
          </Text>
          <Divider top={ah(10)} bg="#E6E7E9" />
        </VStack>
        <VStack height={ah(35)}>
          <HStack justifyContent={"space-between"}>
            <HStack height={ah(32)} alignItems="center">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Show Entries :{" "}
              </Text>
              <Center>
                <LeadsWatchSelect
                  control={control}
                  name={"select"}
                  list={["10", "15", "20", "25"]}
                  value={selectCount}
                  handleChange={(value) => handleChangeSelect(parseInt(value))}
                  width={aw(70)}
                  height={ah(32)}
                />
              </Center>
            </HStack>
            <LeadsWatchButton
              color="#FAFBFD"
              onPress={() => setIsOpen(true)}
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: "10px",
              }}
              label="Add New"
              bg="#0000FF"
              borderColor="#FAFBFD"
            />
          </HStack>
        </VStack>
        <Box>
          <VStack width={aw(1248)}>
            <HStack
              width={aw(1248)}
              justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderLeft: "1px solid #D7D7D7",
                borderRight: "1px solid #D7D7D7",
                borderTop: "1px solid #D7D7D7",
                borderBottom: "1px solid #D7D7D7",
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              {[
                "S.No",
                "Referral Code",
                "No Of Months",
                "Expiry(in days)",
                "Actions",
              ].map((val) => (
                <HStack
                  width={aw(200)}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                  ml={aw(10)}
                >
                  <Text
                    pl={aw(30)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoMedium"}
                    color="#212121"
                  >
                    {val}
                  </Text>
                </HStack>
              ))}
            </HStack>
            {!isLoading ? (
              <ScrollView
                maxHeight={ah(400)}
                showsVerticalScrollIndicator={false}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderbottomWidth: "1px solid #D7D7D7",
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: "1px",
                }}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      width={aw(1248)}
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(400)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={details && details.data}
                  keyExtractor={(item) => item._id}
                  renderItem={({ item, index }) => (
                    <HStack
                      width={aw(1248)}
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderTopWidth: index === 0 ? 0 : "1px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {["index", "code", "months", "expiry", "Actions"].map(
                        (val) => (
                          <HStack
                            width={aw(200)}
                            justifyContent="flex-start"
                            alignItems={"center"}
                            key={val}
                            ml={aw(10)}
                          >
                            {val !== "Actions" ? (
                              <Text
                                pl={aw(30)}
                                fontSize={aw(14)}
                                fontFamily={"RobotoRegular"}
                                color="#212121"
                              >
                                {val === "index" || val === "expiry"
                                  ? val === "index"
                                    ? index + 1
                                    : moment(item.expires).diff(
                                        moment(item.created),
                                        "days"
                                      )
                                  : item[val]}
                              </Text>
                            ) : (
                              <HStack pl={aw(30)}>
                                <Pressable onPress={() => handleEdit(item)}>
                                  <LeadsWatchEditIcon />
                                </Pressable>
                                <Pressable onPress={() => handleView(item)}>
                                  <LeadsWatchEyeIcon />
                                </Pressable>
                              </HStack>
                            )}
                          </HStack>
                        )
                      )}
                    </HStack>
                  )}
                />
              </ScrollView>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(400)}
                style={{
                  border: "1px solid #D7D7D7",
                  borderTopWidth: 0,
                  borderBottomLeftRadius: aw(10),
                  borderBottomRightRadius: aw(10),
                }}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
          </VStack>
        </Box>
        <VStack bottom={aw(5)} position="fixed">
          <LeadsWatchPagination
            totalCount={details && details.count ? details.count : 0}
            currentPage={currentPage}
            pageSize={selectCount}
            onPageChange={(currentPage) => setCurrentPage(currentPage)}
          />
        </VStack>
        <LeadsWatchModal
          height={ah(600)}
          width={aw(400)}
          showModal={isOpen}
          onClose={() => {
            setIsOpen(false);

            setEdit(null);
          }}
          heading={
            <HStack space={ah(10)}>
              <Text
                color={"#000000"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(18)}
              >
                {edit ? "Edit Data" : "Add Data"}
              </Text>
            </HStack>
          }
        >
          <VStack px={aw(20)}>
            <LeadsWatchInput
              height={ah(40)}
              maxLength={6}
              control={control}
              captilize={true}
              name="referral_code"
              placeholder="Referral Code"
              style={{
                marginBottom: ah(17),
              }}
              label={
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Referral Code
                </Text>
              }
              rules={{
                required: {
                  value: true,
                },
                // pattern: {
                //   value: /^[a-zA-Z0-9]{0,6}$/i,
                // },
              }}
              invalid={errors.referral_code ? true : false}
              error={errors.referral_code ? errors.referral_code.message : ""}
            />
            <LeadsWatchInput
              height={ah(40)}
              style={{
                marginTop: ah(20),
                marginBottom: ah(17),
              }}
              type="number"
              labelstyle={{ my: ah(10) }}
              control={control}
              name="expiry_date"
              placeholder="Enter No Of Months"
              label={
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Months
                </Text>
              }
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.expiry_date ? true : false}
              error={errors.expiry_date ? errors.expiry_date.message : ""}
            />
            {/* <HStack mt={ah(20)} width={aw(100)}>
              <input type="number" min={"1"} max={"9"} width={aw(100)} />
            </HStack> */}
            <LeadsWatchInput
              height={ah(40)}
              style={{
                marginTop: ah(20),
                marginBottom: ah(20),
              }}
              control={control}
              name="percent"
              placeholder="Enter percent"
              label={
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Percent
                </Text>
              }
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.percent ? true : false}
              error={errors.percent ? errors.percent.message : ""}
            />
            <LeadsWatchInput
              height={ah(40)}
              style={{
                marginTop: ah(20),
                marginBottom: ah(20),
              }}
              control={control}
              name="expires"
              placeholder="Expires(No of days)"
              label={
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Expires
                </Text>
              }
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.expires ? true : false}
              error={errors.expires ? errors.expires.message : ""}
            />
            <LeadsWatchButton
              isLoading={loading}
              onPress={handleSubmit(handleAdd)}
              color="#FAFBFD"
              bg="#0000FF"
              borderColor="#FAFBFD"
              label={edit ? "Edit Data" : "Add Data"}
              style={{
                width: aw(330),
                height: ah(50),
                borderRadius: "10px",
                marginTop: aw(19),
              }}
            />
          </VStack>
        </LeadsWatchModal>
        <LeadsWatchModal
          height={ah(500)}
          width={aw(400)}
          showModal={viewModalOpen}
          onClose={() => {
            setViewModalOpen(false);
          }}
          heading={
            <HStack space={ah(10)}>
              <Text
                color={"#000000"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(18)}
              >
                Details
              </Text>
            </HStack>
          }
        >
          <VStack space={ah(15)}>
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="black">
                : {singleData && singleData._id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center" color="#919191">
              <Text
                width={aw(91)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Status
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                : {singleData && singleData.active ? "Active" : "Inactive"}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center" color="#919191">
              <Text
                width={aw(91)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Refferal Code
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                : {singleData && singleData.code}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center" color="#919191">
              <Text
                width={aw(91)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Expiry(in days)
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{" "}
                {singleData &&
                  singleData.expires &&
                  moment(singleData.expires).diff(
                    moment(singleData.created),
                    "days"
                  )}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center" color="#919191">
              <Text
                width={aw(91)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Months
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                : {singleData && singleData.months}
              </Text>
            </HStack>
          </VStack>
        </LeadsWatchModal>
      </VStack>
    </VStack>
  );
};

export default Details;
